<template>
  <div>
    <div class="search">
      <div class="title">推广人员列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="会员ID:">
          <el-input v-model="params.userId"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名:">
          <el-input v-model="params.trueName"></el-input>
        </el-form-item>
        <el-form-item label="会员号:">
          <el-input v-model="params.userNo"></el-input>
        </el-form-item>
   
        <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="是否店长:">
            <el-select v-model="params.isShop" placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
        </el-form-item>
        <br/>     
        <el-form-item label="注册时间:">
          <el-date-picker
            :editable="false"           
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="createForm()">生成报表</el-button>

          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
   
      style="width: 100%"

      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column
        align="center"
        prop="userId"
        label="用户ID"
        width="80"
      ></el-table-column>

      <el-table-column
        align="center"
        prop="trueName"
        label="真实姓名"
        width="width"
      ></el-table-column>
      <el-table-column align="center" prop="" label="用户头像" width="width">
        <template slot-scope="{ row }">
          <el-image
            style="width: 40px; height: 40px"
            :src="row.headImgUrl"
            :preview-src-list="[row.headImgUrl]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="phone"
        label="手机号码"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="sex"
        label="性别"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="nickName"
        label="昵称"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="totalIntegral"
        label="总积分"
        
        width="80"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="balance"
        label="余额"
        width="80"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="levelTitle"
        label="会员等级"
        width="120"
      >
      
 
      </el-table-column>
      <el-table-column
        align="center"
        prop="isTj"
        label="是否推广员"
        width="100"
      >
      
 
      </el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="注册时间"
        width="180"
      ></el-table-column>
    
      <el-table-column
        label="操作"
        align="center"
        width="230"
        >
        <template slot-scope="scope">
            <el-button
            type="primary"
            size="mini"
            @click="onGodetail(scope.row.userId, scope.row.staffId)"
            style="margin-right: 20px"
            >查看详情</el-button
          >
            <el-button type="primary"  size="mini" @click="toUser(scope.row)">查看推广人员</el-button>
        </template>
        </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
   
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="分类名称:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
        <el-form-item label="分类图片:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { tgPeople,createFormPeople } from "../../api/vip.js";
import { allLevel } from "../../api/level";
export default {
  data() {
    return {
      dialogVisible: false,
      allLevelList:[],
      options:[
        {
            label:'全部',
            value:-2
        },
        {
            label:'是',
            value:1
        },   {
            label:'否',
            value:0
        },
      ],
      total: 0,

      params: {
        currentPage: 1,
        pageSize: 5,
        userId:null,
        trueName:'',
        phone: "",
        userNo: "",
        isShop: -2,
        beginTime: "",
        endTime: "",
      },
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      addRules: {
        tableData: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onGodetail(userId, staffId) {
      delete this.$route.query.activityId;
      delete this.$route.query.areaId;
      this.$router.push("/vipdetail/" + userId + "?staffId=" + staffId);
    },
    toUser(row){
        this.$router.push('/distributionPeopleUser?tjId='+row.userId)
    },
       //生成报表
       async createForm() {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let date = +new Date();
        const { data } = await createFormPeople(this.params);
        let url = window.URL.createObjectURL(data); //转换文件流未url
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `万旅网推广人员报表_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        loading.close();
    },
        //获取会员等级列表
        async getAllLv() {
      const { data } = await allLevel();
      if (data.code == 0) {
        this.allLevelList = data.data;
      }
    },
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      this.params.beginTime = this.date ? this.date[0] : "";
      this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await tgPeople(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>